/*
***********************************************************************************************
*					GENERICS
***********************************************************************************************
*/

/* Width */
.w-100 { width: 100%; }
.w-20 { width: 20%!important; }

/* Height */
.h-100 { height: 100%; }

/* Position */
.p-relative { position: relative; }
.p-absolute { position: absolute; }

/* Background */
.bg-none { background: none !important; }

/* Overflow + Text */
.o-hidden { overflow: hidden; }
.o-visible { overflow: visible; }
.o-auto { overflow: auto; }
.t-overflow { width: 97%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; float: left; }

/* Line height */
.l-100 { line-height: 100%; }

/* Display */
.d-block { display: inline-block; }

/* Shadow */
.t-s-0 { text-shadow: none; }
.b-s-0 { box-shadow: none; }

/* Border */
.b-0 { border: 0; }
.border-none { border: none!important; }

/* Font */
.f-bold { font-weight: bold; }

/* Paddings */
.p-0 { padding: 0 !important; }
.p-5 { padding: 5px !important; }
.p-10 { padding: 10px !important; }
.p-15 { padding: 15px !important; }
.p-20 { padding: 20px !important; }

.p-t-0 { padding-top: 0 !important; }
.p-t-5 { padding-top: 5px !important; }
.p-t-10 { padding-top: 10px !important; }
.p-t-15 { padding-top: 15px !important; }
.p-t-20 { padding-top: 20px !important; }

.p-b-0 { padding-bottom: 0 !important; }
.p-b-5 { padding-bottom: 5px !important; }
.p-b-10 { padding-bottom: 10px !important; }
.p-b-15 { padding-bottom: 15px !important; }
.p-b-20 { padding-bottom: 20px !important; }

.p-l-0 { padding-left: 0 !important; }
.p-l-5 { padding-left: 5px !important; }
.p-l-10 { padding-left: 10px !important; }
.p-l-15 { padding-left: 15px !important; }
.p-l-20 { padding-left: 20px !important; }

.p-r-0 { padding-right: 0 !important; }
.p-r-5 { padding-right: 5px !important; }
.p-r-10 { padding-right: 10px !important; }
.p-r-15 { padding-right: 15px !important; }
.p-r-20 { padding-right: 20px !important; }

/* Margin */
.m-0 { margin: 0 !important; }
.m-5 { margin: 5px !important; }
.m-10 { margin: 10px !important; }
.m-15 { margin: 15px !important; }
.m-20 { margin: 20px !important; }

.m-t-0 { margin-top: 0 !important; }
.m-t-5 { margin-top: 5px !important; }
.m-t-10 { margin-top: 10px !important; }
.m-t-15 { margin-top: 15px !important; }
.m-t-20 { margin-top: 20px !important; }

.m-b-0 { margin-bottom: 0 !important; }
.m-b-5 { margin-bottom: 5px !important; }
.m-b-10 { margin-bottom: 10px !important; }
.m-b-15 { margin-bottom: 15px !important; }
.m-b-20 { margin-bottom: 20px !important; }
.m-b-25 { margin-bottom: 25px !important; }

.m-l-0 { margin-left: 0 !important; }
.m-l-5 { margin-left: 5px !important; }
.m-l-10 { margin-left: 10px !important; }
.m-l-15 { margin-left: 15px !important; }
.m-l-20 { margin-left: 20px !important; }

.m-r-0 { margin-right: 0 !important; }
.m-r-5 { margin-right: 5px !important; }
.m-r-10 { margin-right: 10px !important; }
.m-r-15 { margin-right: 15px !important; }
.m-r-20 { margin-right: 20px !important; }

/*
***********************************************************************************************
*					CUSTOM MOBILE NAVBAR
***********************************************************************************************
*/

@media (max-width: 769px) {
	.nav.navbar-nav > li.active > a {
		color:#3985f1!important;
	}
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; // remove the gap so it doesn't close
 }

/*
***********************************************************************************************
*					CUSTOM SERVICE GRID OVERVIEW HOVER ANIMATIONS
***********************************************************************************************
*/

/* REMOVE UNDERLINES FROM ANCHORED TEXT */
a:hover,
a:focus {
	text-decoration: none;
}

/* FORCE GRAY COLOR ON ANCHORED TEXT */
a p {
	color: #666;
}

/* ICON RESIZE AND RECOLOR ON HOVER*/    
a:hover.service-overview div.text-icon.wow.fadeInUp.animated span.icon-ar.icon-ar-lg {
	background-color: #DDD;
	border-radius: 25px;
	color: #3985F1;
	-webkit-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;
}

/* ICON RESIZE AND RECOLOR OFF HOVER */ 
a.service-overview div.text-icon.wow.fadeInUp.animated span.icon-ar.icon-ar-lg {
	-webkit-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;
}

/* BACKROUND RESIZE AND RECOLOR ON HOVER */ 
a:hover.service-overview div.text-icon.wow.fadeInUp.animated {
	background-color: rgba(0, 0, 0, .5);
	border-radius: 5px;
	padding: 10px;
	margin: -20px;
	-webkit-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;
}

/* BACKROUND RESIZE AND RECOLOR OFF HOVER */ 
a.service-overview div.text-icon.wow.fadeInUp.animated {
	-webkit-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;
}

/* HEADER RESIZE AND RECOLOR ON HOVER */ 
a:hover.service-overview div.text-icon.wow.fadeInUp.animated div.text-icon-content h3 {
	font-weight: 500;
	color: #FFF;
	-webkit-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;
    -webkit-transform: skewX(-20deg); -moz-transform: skewX(-20deg); -o-transform: skewX(-20deg); transform: skewX(-20deg);
}

/* TEXT RECOLOR ON HOVER */ 
a:hover.service-overview div.text-icon.wow.fadeInUp.animated div.text-icon-content p {
	color: #FFF;
}

/* TEXT AND HEADER OFF HOVER */ 
a.service-overview div.text-icon.wow.fadeInUp.animated div.text-icon-content h3,
a.service-overview div.text-icon.wow.fadeInUp.animated div.text-icon-content p {
	-webkit-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;
}

/* MARGIN FIXES FOR SMALLER SCREENS */ 
@media (max-width: 991px) {

	/* BACKROUND RESIZE AND RECOLOR ON HOVER */ 
	a:hover.service-overview div.text-icon.wow.fadeInUp.animated {
		margin: -10px -10px 10px -10px;
		padding: 10px;
	}
}

/*
***********************************************************************************************
*					MISCELLANEOUS
***********************************************************************************************
*/

/* STYLE TO CONTROL MAP SCROLL / ONTOUCH */
.scrolloff {
        pointer-events: none;
    }
    
/* LINK COLOR CHANGE */
.blue-link {
	color: #3985F1!important;
}


/* COLOR CHANGE FOR ON-HOVER GREEN BUTTON TEXT */ 
a.button.button-3d-action.button-rounded:hover,
a.button.button-3d-action.button-rounded:active,
a.button.button-3d-action.button-rounded:focus {
	color: #FFF;
}

/* MARGIN FIXES FOR TECHNOLOGY ICONS */
@media (max-width: 991px) {
	.tech-icon {
		margin: 5px;
	}
}

/* MARGIN FIXES FOR ALTERNATING WHITE AND BEIGE SECTIONS */
.css-section {
	margin-bottom: 30px;
}

/* MARGIN FIXES FOR ALTERNATING WHITE AND BEIGE SECTIONS */
.css-section.section-lines {
	margin-top: 30px;
	padding-bottom: 25px;
}

/* MARGIN FIXES FOR ALTERNATING WHITE AND BEIGE SECTIONS */
.section-title {
	margin-top: 0px;
}

/* MARGIN FIXES FOR ALTERNATING WHITE AND BEIGE SECTIONS */
section.css-section.section-lines div.container h2 {
	padding-top: 30px;
}

/* PHONE NUMBER FOR HEADER */
a.nav-phone,
a.nav-phone i {
	font-size: 1.25em;
	font-weight: 700;
	color: #CCC;
	-moz-transition: all 0.5s ease; -o-transition: all 0.5s ease; -webkit-transition: all 0.5s ease; transition: all 0.5s ease;
}

/* WHITE FONT HOVER FOR PHONE NUMBER */
a.nav-phone:hover,
a.nav-phone:hover i {
	color: #FFF!important;
	-moz-transition: all 0.5s ease; -o-transition: all 0.5s ease; -webkit-transition: all 0.5s ease; transition: all 0.5s ease;
}

/*
***********************************************************************************************
*					CONTACT FORM
***********************************************************************************************
*/

/* REMOVE FIREFOX ARROW ON DROPDOWN */
.dropdown-select {
	padding-right: 20px;
	-moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

/* REMOVE FIREFOX BORDER ON SELECT */
.dropdown-select:-moz-focusring {
  color:transparent;
  text-shadow:0 0 0 #666; /* your normal text color here */
}

/* LIMIT TEXTBOX RESIZING */
#summary {
	height: 100px;
	min-height: 50px;
	max-width: 100%;
}

/* STYLE SUBMIT BUTTON */
#contact-form button.submit {
	width: 100%;
	height: 75px;
	font-size: 1.5em;
}

/* STYLE FORM ERROR MESSAGES */
.alert {
	padding: 5px;
	margin: 10px 0px 10px 0px;
}

/* REQUIRED FIELD STYLE */
.form-required {
	float: right;
	vertical-align: top;
	font-size: 10px;
	color: #666;
	font-style: italic;
}

/*
***********************************************************************************************
*					ON HOVER LINKS TO PORTFOLIO ITEMS
***********************************************************************************************
*/

/* HIDE LINKS UNTIL ON HOVER IS TRIGGERED */
div.mix a.portfolio-android,
div.mix a.portfolio-apple,
div.mix a.portfolio-web {
	position: absolute;
	top: 80%;
	text-align: center;
    opacity: 0; 
    -webkit-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;
}

/* SHOW LINKS ON HOVER */
div.mix:hover a.portfolio-web,
div.mix:hover a.portfolio-android,
div.mix:hover a.portfolio-apple {
    opacity: 1;
    top: 70%;
    -webkit-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;
}

/* SINGLE LINKS CENTERED */
div.mix a.portfolio-web {
	width: 50%;
	left: 25%;
}

/* DOUBLE LINK FIRST BUTTON */
div.mix a.portfolio-android {
	left: 15%;
	width: 30%;

}

/* DOUBLE LINK SECOND BUTTON */
div.mix a.portfolio-apple {
	left: 55%;
	width: 30%;
}

/*
***********************************************************************************************
*					CUSTOM FOOTER
***********************************************************************************************
*/

.site-map li {
	font-size: 13px;
	margin-left: 5px;
	line-height: 18px;
}

.footer-widget-subtitle {
	font-size: 1.1em;
	border-bottom: 1px solid #3985f1;
	margin-right: 30px;
	color: #fff;
}

.footer-social {
	box-shadow: none;
	background-color: #444;
}

.footer-social li {
	background-color: #333;
}

.footer-social li, .footer-social li a {
	width: 46px;
}

.footer-social li a {
	font-size: 2em;
}

.footer-social li a:hover {
	color: #fff!important;
}

@media (max-width: 769px) {
	#footer-widgets .col-md-4.p-l-0 {
		padding-left: 15px!important;
	}
}
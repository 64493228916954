
ul.navbar-nav {
    li:hover {
        a.dropdown-toggle {
            background-color: #3985f1;
        }
    }
}

.bx-viewport {
    background: inherit!important;
}